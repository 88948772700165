import React from 'react';
import './App.css';

const App = () => {
    return (
        <div>
            <div className="grid place-items-center h-screen">
                <img src="./heavenly-logo.png" className="logo"/>
            </div>

            <div className="flex justify-center mx-auto mb-10 px-6 md:w-1/4">
                <input className="w-full h-12 text-3xl focus:outline-none text-black mr-3 red placeholder-black"
                       type="text"
                       placeholder="Email address"
                />
                <button type="button"
                        className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-l px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 mt-0.5 pl-10 pr-10">Enter
                </button>
            </div>
        </div>
    );
}

export default App;
